import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import server from "../../../server";

const locationTitle = {
  fontSize: "18px",
  fontFamily: "Poppins",
  fontWeight: "600",
};

const addressText = {
  // maxWidth: "100%",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: "400",
};

const EditButton = { backgroundColor: "#F37A20", margin: "5px 5px" };

const DeleteButton = { backgroundColor: "#FF5552", margin: "5px 5px" };

const AddressCard = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState(location?.state?.status);

  const [open1, setOpen1] = useState(false);

  const deleteaddress = async (e) => {
    e.preventDefault();
    const result = await axios.delete(
      `${server}/address/delete/${props.addressdetail._id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // navigate('/profile')
    handleClose();
    props.refresh();
    // console.log(result)
  };

  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);

  const changeAddress = (e) => {
    e.preventDefault();
    if (props.status === "true") {
      // console.log(props.addressdetail)
      toast.success("Address Set", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      if (props.return) {
        // console.log("should go to return page")
        navigate(`/returnorder/${props.return}`, {
          state: {
            name: props.addressdetail.addressLine,
            _id: props.addressdetail._id,
          },
        });
      } else
        navigate("/placeorder", {
          state: {
            name: props.addressdetail.addressLine,
            _id: props.addressdetail._id,
            pincode: props.addressdetail.pincode,
            recipientName: props.addressdetail.recipientName,
            recipientPhoneNo: props.addressdetail.recipientPhoneNo,
            locationType: props.addressdetail.locationType,
          },
        });
    }
  };

  useEffect(() => {
    location?.state?.status ? setStatus(location?.state?.status) : null;
  }, [location?.state?.status]);

  return (
    <>
      <ToastContainer />

      <Box
        sx={{
          padding: "20px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E1E1E1",
          marginTop: "10px",
        }}
        onClick={(e) => {
          status ? changeAddress(e) : null;
        }}
      >
        <Modal
          open={open1}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50vw",
              bgcolor: "background.paper",
              borderRadius: "12px",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Are you Sure, You want to delete the address?
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                className="create-new-post-button"
                style={{
                  margin: "10px 20px",
                  backgroundColor: "#CD7F32",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteaddress(e);
                }}
              >
                Delete
              </button>
              <button
                className="create-new-post-button"
                style={{
                  margin: "10px 20px",
                  backgroundColor: "#CD7F32",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleClose();
                }}
              >
                Cancel
              </button>
            </div>
          </Box>
        </Modal>
        <Box
        // onClick={(e) => changeAddress(e)}
        >
          {/* <Button sx= { { textDecoration: "none"}}> */}
          <Typography sx={locationTitle}>
            {props.addressdetail.locationType}
          </Typography>
          <Typography sx={addressText}>
            {" "}
            {props.addressdetail.addressLine}{" "}
          </Typography>
          {/* </Button> */}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <Link to={{
          pathname: `/editaddress/${props.addressdetail._id}`, 
          state :props.status
          }
        }> */}

          {status ? (
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue="0"
                name="radio-buttons-group"
                onChange={(e) => {
                  // setchoice(e.target.value)
                  // setti
                  changeAddress(e);
                }}
              >
                <FormControlLabel value={0} control={<Radio />} />
              </RadioGroup>
            </FormControl>
          ) : (
            <></>
          )}

          <IconButton
            sx={EditButton}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/editaddress/${props.addressdetail._id}`, {
                state: props.status,
              });
            }}
          >
            <ModeEditOutlinedIcon sx={{ color: "#fff" }} />
          </IconButton>
          {/* </Link> */}
          <Link>
            <IconButton
              sx={DeleteButton}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen1(true);
              }}
            >
              <DeleteOutlineRoundedIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default AddressCard;
