import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import server from "../../server";
import AddressCard from "./Layout/AddressCard";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  margin: "0px 20px 0px 0px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Poppins",
  fontSize: "20px",
  color: "#37474F",
};

const ButtonCart = {
  backgroundColor: "#CD7F32",
  color: "#fff",
  textTransform: "none",
  padding: "10px",
  fontSize: "16px",
  fontWeight: "500",
  width: "100%",
  fontFamily: "Poppins",
  borderRadius: "8px",
  margin: "20px 0px 0px 0px",
  "&.MuiButtonBase-root:hover": {
    background: "#CD7F32",
  },
  position: "relative",
};

const buttonIcon = { position: "absolute", right: "20px" };

const MyAddress = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [addressses, setAddressess] = useState([]);
  const [status, setstatus] = useState();
  const [returnid, setreturn] = useState();

  const getAllAddresses = async () => {
    if (location.state) setstatus(location.state.status);
    if (location.state) setreturn(location.state.return);
    const result = await axios.get(
      `${server}/address/user/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setAddressess(result.data);
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else getAllAddresses();
  }, []);

  useEffect(() => {}, [addressses, status, returnid]);

  return (
    <Box>
      <Box sx={header}>
        <Box
          sx={arrowStyle}
          onClick={() =>
            addressses.length > 0 ? navigate(-1) : navigate("/cart")
          }
          style={{ cursor: "pointer" }}
        >
          <ArrowBackRoundedIcon />
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          My Addresses
        </Typography>
      </Box>
      <Box sx={{ padding: "0px 20px" }}>
        {addressses &&
          addressses.map((curraddress) => {
            // {
            //   console.log(curraddress);
            // }
            return (
              <AddressCard
                addressdetail={curraddress}
                refresh={getAllAddresses}
                key={curraddress._id}
                status={status}
                return={returnid}
              />
            );
          })}
      </Box>
      <Box
        sx={{
          padding: "0px 10px",
          position: "fixed",
          bottom: "100px",
          width: "94%",
        }}
      >
        {/* <Link to="/addaddress" > */}
        <Button
          sx={ButtonCart}
          onClick={(e) => {
            e.preventDefault();
            navigate("/addaddress", { state: { status: status } });
          }}
        >
          <AddRoundedIcon sx={buttonIcon} /> Add New Address
        </Button>
        {/* </Link> */}
      </Box>
    </Box>
  );
};

export default MyAddress;
