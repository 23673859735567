import PrintIcon from "@mui/icons-material/Print";
import { Button } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import {
  deliveryCharge,
  minValueForFreeDelivery,
} from "../../../constants/constants";
import server from "../../server";

const PrintOrder = () => {
  const componentRef = useRef();
  const [order, setOrder] = useState(null); // To store the order data
  const [products, setProducts] = useState([]); // To store product details after fetching
  const [isLoading, setIsLoading] = useState(true); // Loading state while fetching order and products
  const location = useLocation();
  const { orderID } = useParams() || {}; // Getting orderID from location state
  const [productInfo, setProductInfo] = useState(null); // Product details for each item

  // Fetching the order details by orderID
  const getOrderDetails = async (orderID) => {
    try {
      const response = await axios.get(`${server}/orders/id/${orderID}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setOrder(response.data); // Store order data
      fetchProductDetailsForOrder(response.data.productList); // Fetch product details once the order is fetched
    } catch (error) {
      console.error("Error fetching order:", error);
    }
  };

  // Fetch product details by productId
  const fetchProductDetails = async (productId) => {
    try {
      const result = await axios.get(`${server}/products/${productId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      console.log(result.data.product);
      return result.data.product; // Return product details
    } catch (error) {
      if (error.response.data.message === "Product not found") {
        console.log("Product not found, attempting to fetch variant...");
        return getVariants(productId); // Fallback to variants if product not found
      }
    }
  };

  // Fetch variants for the product if the product is not found
  const getVariants = async (productId) => {
    try {
      const result = await axios.get(`${server}/variants/${productId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      console.log(result.data.variant);
      return result.data.variant; // Return variant details
    } catch (error) {
      console.error("Error fetching variant:", error);
    }
  };

  // Fetch product details for all products in the order
  const fetchProductDetailsForOrder = async (orderItems) => {
    const fetchedProducts = await Promise.all(
      orderItems.map(async (item) => {
        const productDetails = await fetchProductDetails(item.productId);
        return {
          ...productDetails,
          itemCount: item.itemCount,
          variant: item.variant,
        };
      }),
    );
    setProducts(fetchedProducts); // Update the state with the fetched product details
    setIsLoading(false); // Set loading to false after data is fetched
  };

  useEffect(() => {
    if (orderID) {
      const fetchOrder = async () => {
        setIsLoading(true);
        await getOrderDetails(orderID);
      };
      fetchOrder();
    }
  }, [orderID]);

  // Calculate the total amount for the order
  const calculateTotal = () => {
    return products.reduce(
      (total, product) => total + product.itemCount * product.price,
      0,
    );
  };

  const total = calculateTotal();

  const delivery =
    order?.deliveryCharge || total < minValueForFreeDelivery ? deliveryCharge : 0;

  if (isLoading) {
    return <div className="text-center text-xl">Loading...</div>;
  }

  return (
    <div className="p-5 max-w-full sm:max-w-2xl mx-auto bg-gray-50 rounded-lg shadow-lg h-[90vh]">
      <div ref={componentRef} className="bg-white p-5 border rounded-lg">
        <header className="text-center mb-5">
          <h2 className="text-2xl sm:text-3xl font-bold">Agarwal Sales</h2>
          <p className="text-sm sm:text-base text-gray-600">
            7/A+B, Dnydeep Colony, Thorat Colony, Hingne Budrukh, Karve Nagar,
            Pune, Maharashtra 411052
          </p>
        </header>
        <div className="mb-5">
          <h3 className="text-xl sm:text-2xl font-semibold">Order Details</h3>
          <p className="text-sm sm:text-base text-gray-700">
            Order ID: {order?._id}
          </p>
          <p className="text-sm sm:text-base text-gray-700">
            Recipient: {order?.recipientName}
          </p>
          <p className="text-sm sm:text-base text-gray-700">
            Phone: {order?.recipientPhoneNo}
          </p>
          <p className="text-sm sm:text-base text-gray-700">
            Address: {order?.addressLine}, {order?.pincode}
          </p>
        </div>
        <table className="w-full table-auto mb-5 text-sm sm:text-base">
          <thead>
            <tr>
              <th className="border-b px-2 sm:px-4 py-2 text-left">Product</th>
              <th className="border-b px-2 sm:px-4 py-2 text-left">Quantity</th>
              <th className="border-b px-2 sm:px-4 py-2 text-left">Price</th>
              <th className="border-b px-2 sm:px-4 py-2 text-left">Total</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={index}>
                <td className="border-b px-2 sm:px-4 py-2">{product?.name}</td>
                <td className="border-b px-2 sm:px-4 py-2">
                  {product?.itemCount}
                </td>
                <td className="border-b px-2 sm:px-4 py-2">
                  {product?.price?.toFixed(2)} Rs
                </td>
                <td className="border-b px-2 sm:px-4 py-2 text-right">
                  {(product?.itemCount * product?.price).toFixed(2)} Rs
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="3" className="px-2 sm:px-4 py-2 text-left">
                Delivery Charge:
              </td>
              <td className="px-2 sm:px-4 py-2 text-right">{delivery} Rs</td>
            </tr>
            <tr>
              <td colSpan="3" className="px-2 sm:px-4 py-2 text-left font-bold">
                Grand Total:
              </td>
              <td className="px-2 sm:px-4 py-2 text-right font-bold">
                {(total + delivery).toFixed(2)} Rs
              </td>
            </tr>
          </tfoot>
        </table>
        <footer className="text-center mt-5">
          <p className="text-sm sm:text-base">
            Thank you for your purchase! 😊
          </p>
          <p className="text-sm sm:text-base">
            Order again at{" "}
            <a
              href="https://agarwalsales.com/"
              className="text-blue-600 hover:text-blue-800"
            >
              agarwalsales
            </a>
          </p>
          {/* <p className="text-sm sm:text-base">Enjoy shopping with us!</p> */}
        </footer>
        <div className="text-center mt-5 content-center">
          <img
            src="/agarwal-qr.JPG"
            alt=""
            className="object-contain w-24 mx-auto"
          />
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              color="primary"
              startIcon={<PrintIcon />}
              className="sm:text-lg"
            >
              Download PDF
            </Button>
          )}
          content={() => componentRef.current}
          documentTitle="Order Invoice"
        />
      </div>
      <div className="h-32"></div>
    </div>
  );
};

export default PrintOrder;
